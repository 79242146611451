import pImg1 from '../images/portfolio/portfolio_item_image_1.webp'
import pImg2 from '../images/portfolio/portfolio_item_image_2.webp'
import pImg3 from '../images/portfolio/portfolio_item_image_3.webp'
import pImg4 from '../images/portfolio/p2.webp'
import pImg5 from '../images/portfolio/p1.webp'
import pImg6 from '../images/portfolio/property.webp'
import case10 from '../images/case/case10.webp'
import case11 from '../images/case/case11.webp'

import pImg7 from '../images/portfolio/portfolio_item_image_4.webp'
import pImg8 from '../images/portfolio/portfolio_item_image_5.webp'
import pImg9 from '../images/portfolio/portfolio_item_image_6.webp'
import pImg10 from '../images/portfolio/portfolio_item_image_7.webp'
import pImg11 from '../images/portfolio/portfolio_item_image_8.webp'
import pImg12 from '../images/portfolio/portfolio_item_image_9.webp'
import pImg13 from '../images/portfolio/portfolio_item_image_10.webp'
import pImg14 from '../images/portfolio/portfolio_item_image_11.webp'


import tImg1 from '../images/case/icon_angular.svg'
import tImg2 from '../images/case/icon_elephent.svg'
import tImg3 from '../images/case/icon_netcore.svg'
import tImg4 from '../images/case/vuejs.svg'
import tImg5 from '../images/case/icon_python.svg'
import tImg6 from '../images/case/c.svg'
import tImg7 from '../images/case/react.svg'
import tImg8 from '../images/case/icons8-salesforce.svg'
import tImg9 from '../images/case/java.svg'
import tImg10 from '../images/case/vx.png'

const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '2',
        pImg: pImg2,
        title: 'TOEFL Coaching',
        slug: 'TOEFL-Coaching',
        sub: '',
        description: 'Our experienced coaches are not just educators; they are partners in your journey..',
    },
    {
        Id: '3',
        pImg: pImg3,
        title: 'Dashboard Design',
        slug: 'Dashboard-Design',
        sub: 'Web Design',
        description: 'The contents of the citizenship test typically cover a range of subjects ...',
    },
    {
        Id: '4',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '5',
        pImg: pImg2,
        title: 'TOEFL Coaching',
        slug: 'TOEFL-Coaching',
        sub: '',
        description: 'Our experienced coaches are not just educators; they are partners in your journey..',
    },
    {
        Id: '6',
        pImg: pImg3,
        title: 'Dashboard Design',
        slug: 'Dashboard-Design',
        sub: 'Web Design',
        description: 'The contents of the citizenship test typically cover a range of subjects ...',
    },
    {
        Id: '7',
        pImg: pImg4,
        title: 'MedX Software Solutions',
        slug: 'MedX-Software-Solutions',
        sub: 'Healthcare IT',
        Industry: 'Healthcare',
        Country: 'Germany, Issum',
        Technologies1: tImg1,
        Technologies2: tImg2,
        description: 'MedX Software Solutions was tasked with building an advanced healthcare IT platform that could securely manage patient data while integrating with existing medical systems. The key challenge was ensuring interoperability between various data formats and meeting stringent regulatory standards. Our solution utilized cloud-based technologies with high-level encryption to ensure both security and compliance, delivering a scalable system that streamlined operations for healthcare providers and improved patient outcomes.'
    },
    {
        Id: '8',
        pImg: pImg5,
        title: 'Emirates Health Simulation',
        slug: 'Emirates-Health-Simulation',
        sub: 'Healthcare & Education',
        Industry: 'Healthcare & Medical Training',
        Country: 'UAE, Abu Dhabi',
        Technologies1: tImg1,
        Technologies2: tImg3,
        description: 'Emirates Health Simulation sought to advance medical education in the UAE by incorporating virtual reality and AI into their training programs. The challenge was creating a platform that could simulate a wide variety of medical procedures, providing trainees with realistic, hands-on experience. By integrating AI-driven feedback and immersive VR environments, we developed a comprehensive solution that allowed medical professionals to practice in a controlled, risk-free setting, greatly enhancing the quality of training in the region.'
    },
    {
        Id: '9',
        pImg: pImg6,
        title: 'Dubai Property Ease',
        slug: 'DubaiPropertyEase',
        sub: 'Real Estate Technology',
        Industry: 'Real Estate',
        Country: 'Dubai, UAE',
        Technologies1: tImg4,
        Technologies2: tImg5,
        description: 'DubaiPropertyEase aimed to streamline property transactions in Dubai’s booming real estate market. The key challenge was developing a digital platform that could handle a high volume of listings while offering a seamless, secure user experience for buyers, sellers, and agents. We created a scalable, cloud-based system that integrated real-time property data and secure payment gateways, enabling faster transactions and providing greater transparency in the real estate process. This solution significantly enhanced the user experience and optimized property dealings in Dubai.'
    },
    {
        Id: '10',
        pImg: case11,
        title: 'Quiclo Laundry',
        slug: 'Quiclo-Laundry',
        sub: 'Laundry Automation',
        Industry: 'Services',
        Country: 'India, Hyderabad',
        Technologies1: tImg7,
        Technologies2: tImg8,
        description: 'Quiclo, a mid-sized laundry service provider in Hyderabad, faced operational inefficiencies, inconsistent service quality, and difficulties in managing peak loads. Grahansoft designed and developed a “Laundry Line Automation Solution” and a “Self-Service Mobile App,” which improved operational efficiency by reducing manual work. These systems provided better management of laundry machines, staff schedules, and inventory, resulting in a seamless and convenient experience for both self-service and commercial customers.'
    }, 
    {
        Id: '11',
        pImg: case10,
        title: 'Novus Green',
        slug: 'Novus-Green',
        sub: 'Solar Panel IoT Solutions',
        Industry: 'Renewable Energy',
        Country: 'India, Hyderabad',
        Technologies1: tImg6,
        Technologies2: tImg10,
        Technologies3: tImg9,
        Technologies4: tImg5,
        description: 'IoT engineers from Grahansoft significantly impacted the solar panel industry at Novus Green by developing smart technologies that enhanced the efficiency, performance, and management of solar energy systems. The team implemented a cloud-based platform that allowed sensors to feed real-time data to central monitoring systems. Complex algorithms were developed to analyze the aggregated data, enabling predictive maintenance, fault detection, and performance optimization. The system dynamically adjusted solar panels based on weather conditions, and the integration with energy storage systems ensured efficient use and distribution of stored energy.'
    },        
    {
        Id: '12',
        pImg: pImg7,
        title: 'Driving Digital Transformation Explore the Depth of Our IT Projects',
        slug: 'Driving-Digital-Transformation-Explore-the-Depth-of-Our-IT Projects',
        thumb1: 'Logo Design',
        thumb2: 'Finance',
        category: 'technology',
    },
    {
        Id: '17',
        pImg: pImg8,
        title: 'Explore Our IT Solutions Portfolio for Public Sector Organizations',
        slug: 'Explore-Our-IT-Solutions',
        thumb1: 'App Design',
        thumb2: 'Public',
        category:'helpdesk'
    },
    {
        Id: '18',
        pImg: pImg9,
        title: 'Innovative Solutions Showcase the Diversity of Our IT Portfolio',
        slug: 'Innovative-Solutions-Showcase-the-Diversity-of-Our-IT-Portfolio',
        thumb1: 'Card Design',
        thumb2: 'Transpiration',
        category:'analysis'
    },
    {
        Id: '19',
        pImg: pImg10,
        title: 'Tech Triumphs Celebrating Our Achievements in IT Solutions',
        slug: 'Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions',
        thumb1: 'Web Design',
        thumb2: 'Logistic',
        category: 'technology',
    },
    {
        Id: '20',
        pImg: pImg11,
        title: 'Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions',
        slug: 'Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions',
        thumb1: 'Web Design',
        thumb2: 'Fution',
        category: 'analysis',
    },
    {
        Id: '21',
        pImg: pImg12,
        title: 'Cloud Migration and Integration Project IT Solutions Portfolio',
        slug: 'Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio',
        thumb1: 'Web Design',
        thumb2: 'Energy',
        category: 'helpdesk'
    },
    {
        Id: '22',
        pImg: pImg13,
        title: 'Pioneering Progress Exploring the Evolution and Impact of',
        slug: 'Pioneering-Progress-Exploring-the-Evolution-and-Impact-of',
        thumb1: 'Web Design',
        thumb2: 'Health',
        category: 'marketing'
    },
    {
        Id: '23',
        pImg: pImg14,
        title: 'Unlocking Potential Explore Our Comprehensive IT Portfolio',
        slug: 'Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio',
        thumb1: 'Web Design',
        thumb2: 'Industry',
        category: 'technology',
    },

]

export default Project;
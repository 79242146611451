
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section id="faq" className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        F.A.Q.
                    </div>
                    <h2 className="heading_text mb-0">
                        Need a <mark>Support?</mark>
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                Q. How do I choose the right software development company?
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className="acc_body">
                                <div className="text_a">A.</div>
                                <p>
                                    Choosing the right software development company involves assessing the company’s experience, technology expertise, portfolio, and ability to deliver within timelines and budgets. It’s crucial to ensure they have experience in your industry and can provide ongoing support post-development.
                                </p>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                Q. What is the typical cost of custom software development?
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className="acc_body">
                                <div className="text_a">A.</div>
                                <p>
                                    The cost of custom software development varies based on project complexity, technology stack, and the development team’s location. On average, a small project can cost anywhere from $10,000 to $50,000, while complex enterprise solutions can exceed $100,000.
                                </p>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                Q. How long does it take to develop custom software?
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className="acc_body">
                                <div className="text_a">A.</div>
                                <p>
                                    The timeline for custom software development depends on the scope and complexity of the project. Small-scale applications can take 2-3 months, while more complex systems may require 6-12 months or longer. Clear requirements and regular feedback can speed up the process.
                                </p>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                Q. What technologies do you specialize in for software development?
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className="acc_body">
                                <div className="text_a">A.</div>
                                <p>
                                    Our team specializes in a wide range of technologies including .NET, Java, Python, Node.js, React, Angular, Android, and iOS development. We also work with cloud platforms like AWS, Azure, and Google Cloud, and utilize databases like PostgreSQL, MongoDB, and SQL Server.
                                </p>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. How do you ensure the quality of the software?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className="acc_body">
                                <div className="text_a">A.</div>
                                <p>
                                    We follow industry-standard QA practices, including unit testing, integration testing, performance testing, and user acceptance testing (UAT). Our QA team works alongside developers throughout the project to identify and fix issues early, ensuring a robust and reliable product.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Grahansoft Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Grahansoft Shape Angle" />
            </div>
        </section>
    )
};
export default FaqSection;

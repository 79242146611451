import sImg1 from '../images/services/service_image_1.webp'
import sImg2 from '../images/services/service_image_2.webp'
import sImg3 from '../images/services/service_image_3.webp'
import sImg4 from '../images/services/service_image_4.webp'
import sImg5 from '../images/services/service_image_5.webp'

import icon1 from '../images/services/salesforce.png'
import icon2 from '../images/services/agile.png'
import icon3 from '../images/services/ds.png'
import icon4 from '../images/services/es.png'
import icon5 from '../images/services/internet.png'
import icon6 from '../images/services/cloud.png'

import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'



const Services = [
    {
        Id: '1',
        sImg:sImg1,
        title: 'Salesforce Services',
        slug: 'Salesforce-Services',
        thumb1:'Strategy',
        thumb2:'Consultation',
        col:'col-lg-6',
        description:'Visit new places to discover with a Tourist Visa. We deliver your documents ...',
    },
    {
        Id: '2',
        sImg:sImg2,
        title: 'Data Tracking and Security',
        slug: 'Data-Tracking-and-Security',
        thumb1:'Management',
        thumb2:'Transfer',
        col:'col-lg-6',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '3',
        sImg:sImg3,
        title: 'Website Development',
        slug: 'Website-Development',
        thumb1:'Landing Page',
        thumb2:'Plugins',
        col:'col-lg-4',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '4',
        sImg:sImg4,
        title: 'Modern Technology Solution',
        slug: 'Modern-Technology-Solution',
        thumb1:'Consultation',
        thumb2:'solution',
        col:'col-lg-4',
        description:'Embarking on a journey of higher education in a foreign country opens doors to...',
    },
    {
        Id: '5',
        sImg:sImg5,
        title: 'UI/UX Design Services',
        slug: 'UI-UX-Design-Services',
        thumb1:'Website',
        thumb2:'Mobile App',
        col:'col-lg-4',
        description:'Expert Guidance for a Seamless Immigration Journey Expert Guidance...',
    },
    {
        Id: '6',
        sImg: icon1,
        title: 'Salesforce Services',
        slug: 'salesforce-services',
        features: ['Salesforce CRM Implementation', 'Salesforce App Development', 'Salesforce Consulting', 'Salesforce Data Migration'],
        icon: icon1
    },
    {
        Id: '7',
        sImg: icon2,
        title: 'DevOps Services',
        slug: 'devops-services',
        features: ['CI/CD Pipeline Implementation', 'Infrastructure as Code (IaC)', 'Monitoring & Logging', 'Automation'],
        icon: icon2
    },
    {
        Id: '8',
        sImg: icon3,
        title: 'Data Engineering ',
        slug: 'data-engineering-services',
        features: ['Data Pipeline Development', 'ETL Services', 'Data Lake Design', 'Data Warehousing'],
        icon: icon3
    },
    {
        Id: '9',
        sImg: icon4,
        title: 'Embedded Systems ',
        slug: 'embedded-systems-services',
        features: ['Embedded Software Development', 'Hardware Integration', 'Firmware Development', 'Real-Time Systems'],
        icon: icon4
    },
    {
        Id: '10',
        sImg: icon5,
        title: 'IoT Services',
        slug: 'iot-services',
        features: ['IoT Platform Development', 'Device Connectivity & Management', 'IoT Data Analytics', 'IoT Security'],
        icon: icon5
    },
    {
        Id: '11',
        sImg: icon6,
        title: 'Integrations',
        slug: 'cross-service-integration',
        features: ['API Integration', 'Microservices Architecture', 'Cross-Platform Data Sharing', 'System Interoperability'],
        icon: icon6
    },
    {
        Id: '12',
        sImg:icon7,
        title: 'Strategic Planning and Execution',
        slug: 'Strategic-Planning-and-Execution',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '13',
        sImg:icon8,
        title: 'Business Process Optimization',
        slug: 'Business-Process-Optimization',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '14',
        sImg:icon9,
        title: 'Digital Transformation Consulting',
        slug: 'Digital-Transformation-Consulting',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '15',
        sImg:icon10,
        title: 'Strategic Planning and Execution',
        slug: 'Strategic-Planning-and-Executions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '16',
        sImg:icon11,
        title: 'Change Management Solutions',
        slug: 'Change-Management-Solutions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '17',
        sImg:icon12,
        title: 'Performance Metrics and KPI Development',
        slug: 'Performance-Metrics-and-KPI-Development',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
]    

export default Services;